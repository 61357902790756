import React, { ChangeEvent, ReactElement, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { AssetPage } from "@/lib/api/types";
import { ParseName } from "../../../lib/api/name";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import CustomPagination, {
  DEFAULT_PAGE_SIZE,
} from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";

interface AssetTableProps {
  assetdata: AssetPage | null;
}

const AssetTable: React.FC<AssetTableProps> = ({ assetdata }) => {
  console.log("assetdata", assetdata);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const navigate = useNavigate();

  const { page, pageSize, pageSizes, totalItems, setTotalItems, handlePageChange } = usePagination({
    initialPageSize: DEFAULT_PAGE_SIZE,
  });

  const headers = useMemo(
    () => [
      {
        key: "chain",
        header: "Chain",
      },
      {
        key: "symbol",
        header: "Asset Symbol",
      },
      { key: "notes", header: "Description" },
      {
        key: "decimals",
        header: "Decimal",
      },
      {
        key: "variant",
        header: "Variant",
      },
    ],
    [],
  );

  // Extract description from JSON string
  const extractDescription = (jsonString: string): string | undefined => {
    try {
      const data = JSON.parse(jsonString);
      return data.description;
    } catch (error) {
      console.error("Invalid JSON string provided:", error);
      return undefined;
    }
  };

  // Prepare and filter rows
  const filteredRows = useMemo(() => {
    if (!assetdata || !assetdata.assets) return [];

    const rows = assetdata.assets.map((item, index) => ({
      id: `row-${index}`,
      ...item,
      symbol: ParseName([item.name ?? ""], "Asset").resourceId || "NA",
      chain: ParseName([item.name ?? ""], "Asset").parentId || "NA",
      notes:
        item.notes && typeof item.notes === "object" && item.notes.description
          ? item.notes.description
          : extractDescription(item?.notes?.toString() ?? "") || "",
    }));

    const filtered = rows.filter((row) => {
      const searchableText = `${row.state ?? ""} ${row.decimals ?? ""} ${row.variant ?? ""} ${row.symbol}`;
      return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
    });

    // Sort rows based on sortDirection
    return filtered.sort((a, b) => {
      if (sortDirection === "DESC") {
        return a.symbol.localeCompare(b.symbol);
      } else {
        return b.symbol.localeCompare(a.symbol);
      }
    });
  }, [assetdata, searchQuery, sortDirection]);

  // Update totalItems for pagination
  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows.length, setTotalItems]);

  // Paginate rows
  const paginatedRows = useMemo(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return filteredRows.slice(start, end);
  }, [filteredRows, page, pageSize]);

  const handleSort = (columnName: string) => {
    if (columnName === "symbol") {
      setSortDirection((currentDirection) => (currentDirection === "ASC" ? "DESC" : "ASC"));
    }
  };

  const handleRowClick = (row: any) => {
    const symbol = row.cells.find((cell: any) => cell.info.header === "symbol")?.value;
    const chain = row.cells.find((cell: any) => cell.info.header === "chain")?.value;
    if (symbol && chain) {
      navigate(`/assets/chain/${chain}/assets/${symbol}`);
    }
  };

  const renderCellContent = (cell: any): JSX.Element => {
    if (cell.info.header === "chain") {
      return (IconCellRenderer({ value: cell.value }) as ReactElement) || <></>;
    }
    return <span>{cell.value}</span>;
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            //@ts-expect-error error
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>

      <DataTable
        size="md"
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  //@ts-expect-error error
                  <TableHeader
                    //@ts-expect-error error
                    key={header.key}
                    {...getHeaderProps({
                      header,
                      isSortable: header.key === "symbol",
                      onClick: () => handleSort(header.key),
                    })}
                  >
                    {header.header}
                    {/* Optionally, you can add sort indicators here */}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  //@ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />

      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default AssetTable;
