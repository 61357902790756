import React, { ChangeEvent, useState, useEffect, useMemo } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";
import useAvailableUsers from "../hooks/useAvailableUsers";
import { TreasuryUser } from "../../../features/organizationManagement/types/treasuryUserType";
import { UserPage } from "../../../lib/api/types";
import { useUsers } from "../api/useUsers";
import CustomPagination, {
  DEFAULT_PAGE_SIZES,
} from "../../../components/pagination/CustomPagination";

interface UsersTableProps {
  initialUserPage: UserPage;
}

const UsersTable: React.FC<UsersTableProps> = ({ initialUserPage }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalItems, setTotalItems] = useState(initialUserPage.total_size);
  const [allUsers, setAllUsers] = useState(initialUserPage.users);

  const availableUsers = useAvailableUsers();

  const userPage = useUsers(1, 100000, ""); // Adjust 100000 based on expected max users

  useEffect(() => {
    if (userPage) {
      setAllUsers(userPage.users);
      setTotalItems(userPage.total_size);
      setCurrentPage(1);
    }
  }, [userPage]);

  const headers = useMemo(
    () => [
      { key: "name", header: "Name" },
      { key: "roles", header: "Roles" },
      { key: "variant", header: "Variant" },
      { key: "clerkEmail", header: "SSO ID" },
      { key: "ssoUser", header: "SSO email" },
    ],
    [],
  );

  const getSSOUser = useMemo(() => {
    return (userId: string): TreasuryUser | undefined => {
      const user = availableUsers.find((user) => user.name === userId);
      return user ? user : undefined;
    };
  }, [availableUsers]);

  const filteredUsers = useMemo(() => {
    return (
      allUsers?.filter((user) => {
        const name = ParseName([user.name ?? ""], "User").resourceId || "";
        const roles =
          user.roles?.map((role) => ParseName([role ?? ""], "Role").resourceId).join(" ") || "";
        const clerkEmail = user.labels ? user.labels["sso-user"] : "";
        const variant = user.variant || "";

        const searchableText = `${name} ${variant} ${roles} ${clerkEmail}`.toLowerCase();
        return searchableText.includes(searchQuery.toLowerCase());
      }) || []
    );
  }, [allUsers, searchQuery]);

  useEffect(() => {
    setTotalItems(filteredUsers.length);
    setCurrentPage(1);
  }, [filteredUsers]);

  const paginatedUsers = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredUsers.slice(startIndex, endIndex);
  }, [filteredUsers, currentPage, pageSize]);

  const displayRows = useMemo(() => {
    return paginatedUsers.map((user, index) => ({
      id: `row-${user.name || index}`, // Ensure unique ID
      name: ParseName([user.name ?? ""], "User").resourceId || "NA",
      roles:
        user.roles?.map((role) => ParseName([role ?? ""], "Role").resourceId).join(" ") || "NA",
      clerkEmail: user.labels ? user.labels["sso-user"] : "NA",
      ssoUser: getSSOUser(user.labels ? user.labels["sso-user"] : "")?.emails[0] || "NA",
      variant: user.variant || "NA",
    }));
  }, [paginatedUsers, getSSOUser]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = ({
    page,
    pageSize: newPageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const renderCellContent = (cell: any) => {
    return cell.value;
  };

  return (
    <TableContainer>
      <TableToolbar>
        <TableToolbarSearch
          value={searchQuery}
          // @ts-expect-error error
          onChange={handleSearchChange}
          expanded
          placeholder="Search"
        />
      </TableToolbar>
      <DataTable
        rows={displayRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                // @ts-expect-error error
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />

      <CustomPagination
        totalItems={totalItems || 0}
        pageSize={pageSize}
        pageSizes={DEFAULT_PAGE_SIZES}
        page={currentPage}
        onPageChange={handlePageChange}
      />
    </TableContainer>
  );
};

export default UsersTable;
